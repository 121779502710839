const colors = {
  primary: '#90CAF9',
  secondary: '#E0E0E0',
  background: '#FFFFFF',
};

const about = {
  firstName: 'Sam',
  lastName: 'Labrador',
  shortDesc: `I'm a SDE located in Rancho Cucamonga, CA.`,
  longDesc: [
    `I am currently working as a software development engineer at ESRI on the Workflow Manager team. I work with a wide variety of technologies, contribute to story planning, and draft technical documentation.`,
    `I have 3 years of professional full stack development experience. However, I've worked with a wide variety of other technologies such as embedded systems, hardware accelerated code (via FPGAs), and mobile app development.`,
    `I'm currently not open to full time work. However, I am trying to get into freelancing on my free time, so if you have an idea or problem that needs solving, feel free to send me an email below.`
  ],
  email: '',
  github: 'https://github.com',
  linkedIn: 'https://linkedin.com',
};

const projects = [
  {
    name: 'Monophonic Synthesizer',
    desc: 'A digital electronic synthesizer that users ',
    image: '/MonophonicSynth.png',
    demo: 'https://www.youtube.com/watch?v=WkTTCQ1gS_M',
    source: 'https://github.com/SamuelLabrador/CS-EE120B-FinalProject-Synthesizer',
  },
  {
    name: 'Highway Analytics',
    desc: 'Traffic Analytics via Image Recognition. If you would like a demo, please send me a message.',
    image: './trafficCamera.jpg',
    demo: '',
    source: 'https://github.com/SamuelLabrador/has_backend',
  },
  {
    name: 'Security Trader',
    desc: 'A stock trading game',
    image: './SecurityTraderMenu.png',
    demo: '',
    source: 'https://github.com/SamuelLabrador/SecurityTrader',
  },
];

const skills = [{
    skill: 'Scala',
    link: 'https://cdn.svgporn.com/logos/scala.svg',
  },
  {
    skill: 'Python',
    link: 'https://cdn.svgporn.com/logos/python.svg',
  },
  {
    skill: 'Docker',
    link: 'https://cdn.svgporn.com/logos/docker-icon.svg',
  },
  {
    skill: 'Javascript',
    link: 'https://cdn.svgporn.com/logos/javascript.svg',
  },
  {
    skill: 'Angular',
    link: 'https://cdn.svgporn.com/logos/angular-icon.svg',
  },
  {
    skill: 'Kubernetes',
    link: 'https://cdn.svgporn.com/logos/kubernetes.svg',
  },
  {
    skill: 'MySQL',
    link: 'https://cdn.svgporn.com/logos/mysql.svg',
  },
  {
    skill: 'React',
    link: 'https://cdn.svgporn.com/logos/react.svg',
  },
  {
    skill: 'Node',
    link: 'https://cdn.svgporn.com/logos/nodejs.svg',
  },
  {
    skill: 'AWS',
    link: 'https://cdn.svgporn.com/logos/aws.svg',
  },
  {
    skill: 'C++',
    link: 'https://cdn.svgporn.com/logos/c-plusplus.svg',
  },
  {
    skill: 'Git',
    link: 'https://cdn.svgporn.com/logos/git-icon.svg',
  }];

export default {
  colors, about, projects, skills
}

export { colors, about, projects, skills };
